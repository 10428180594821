import { axiosClient, axiosSiplyClient } from './apiSetup'
import { EndPoints, SiplyEndPoints } from './endPoints'
import {
  ChitPlansResponse,
  RegisterUserRequest,
  RegisterUserResponse,
  OtpVerifyRequest,
  OtpVerifyResponse,
} from '../../components/homePage/chitForm/chitFormSlice.models'

export async function getChitPlansPublic(): Promise<ChitPlansResponse> {
  try {
    const res = await axiosClient.get(EndPoints.getAllChits)
    console.log(res.data)
    return res.data as ChitPlansResponse
  } catch (error) {
    console.error(error)
    return { status: false }
  }
}

export async function saveLead(props: {
  mobileNumber: string
  name: string
  whatsappConsent: boolean
}) {
  try {
    const res = await axiosClient.post(EndPoints.saveLead, {
      mobileNumber: props.mobileNumber,
      name: props.name,
      whatsappConsent: props.whatsappConsent,
    })
    console.log(res.data)
    return res.data as { status: boolean; message: string }
  } catch (error) {
    console.error(error)
    return { status: false, message: 'error' }
  }
}

export async function addEmail(
  email: string
): Promise<{ status: boolean; message: string }> {
  try {
    const res = await axiosClient.post(EndPoints.subscribeForOffers, {
      emailId: email,
    })
    console.log(res.data)
    return res.data as { status: boolean; message: string }
  } catch (error) {
    console.error(error)
    return { status: false, message: 'error' }
  }
}

export async function registerUser(
  props: RegisterUserRequest
): Promise<RegisterUserResponse> {
  try {
    const res = await axiosSiplyClient.post(SiplyEndPoints.registerUser, {
      mobileNumber: props.mobileNumber,
      full_name: props.name,
      state: props.state,
      medium: 'website',
      whatsappConsent: props.whatsappConsent,
    })
    console.log(res.data)
    return res.data as RegisterUserResponse
  } catch (error) {
    console.error(error)
    return { status: false, message: 'error' }
  }
}

export async function verifyOtp(
  props: OtpVerifyRequest
): Promise<OtpVerifyResponse> {
  try {
    const res = await axiosSiplyClient.post(SiplyEndPoints.otpVerify, {
      mobileNumber: props.mobileNumber,
      otp: props.otp,
      medium: 'website',
      agent_code: props.agentCode,
    })
    console.log(res.data)
    return res.data as OtpVerifyResponse
  } catch (error) {
    console.error(error)
    return { status: false, message: 'error', token: '' }
  }
}

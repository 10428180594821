import createTheme from '@mui/material/styles/createTheme'
import ThemeProvider from '@mui/system/ThemeProvider'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { lazy, Suspense } from 'react'
import { WholeScreenLoader } from './components/common/CommonPage'
import { isAuthenticated } from './components/common/helperFunctions'
import { Portfolio } from './components/chitsDashboard/portfolio/Portfolio'
import { Analytics } from './core/analytics/Analytics'

const HomePage = lazy(
  async () => await import('./components/homePage/HomePage')
)
const ChitsDashboardPage = lazy(
  async () => await import('./components/chitsDashboard/ChitsDashboardRedirect')
)
const SelectChitPlan = lazy(
  async () =>
    await import('./components/chitsDashboard/selectChitPlan/SelectChitPlan')
)
const KycHome = lazy(
  async () => await import('./components/chitsDashboard/kycHome/KycHome')
)
const CheckEligibility = lazy(
  async () =>
    await import(
      './components/chitsDashboard/checkEligibility/CheckEligibility'
    )
)
const EligiblePlans = lazy(
  async () =>
    await import('./components/chitsDashboard/eligiblePlans/EligiblePlans')
)
const ReviewYourPlan = lazy(
  async () =>
    await import('./components/chitsDashboard/reviewPlan/ReviewYourPlan')
)
const NotFound = lazy(
  async () => await import('./components/common/notFound/NotFound')
)

const ChitsPaymentStatus = lazy(
  async () =>
    await import('./components/chitsDashboard/chitsPayment/ChitsPaymentStatus')
)
const ESignAgreement = lazy(
  async () => await import('./components/chitsDashboard/eSign/ESignAgreement')
)
const Dashboard = lazy(
  async () => await import('./components/chitsDashboard/dashboard/Dashboard')
)

const theme = createTheme({
  palette: {
    primary: {
      main: '#6027d0',
    },
    secondary: {
      main: '#464646',
    },
    text: {
      primary: '#464646',
      secondary: '#3987ba',
    },
  },
  typography: {
    fontFamily: [
      'Inter',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
})

function PrivateRoute(props: { children: JSX.Element }) {
  return isAuthenticated() ? props.children : <Navigate to="/" />
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Suspense fallback={<WholeScreenLoader />}>
          <Routes>
            {/* root */}
            <Route path="/" element={<HomePage />} />

            <Route
              path="/chit-dashboard"
              element={
                <PrivateRoute>
                  <ChitsDashboardPage />
                </PrivateRoute>
              }
            >
              <Route index element={<Portfolio />} />
              <Route path=":userGoalId" element={<Dashboard />} />
              <Route path="select-plan" element={<SelectChitPlan />} />
              <Route path="check-eligibility" element={<CheckEligibility />} />
              <Route path="select-eligible-plan" element={<EligiblePlans />} />
              <Route path="kyc" element={<KycHome />} />
              <Route path="review-plan" element={<ReviewYourPlan />} />
              <Route path="e-sign" element={<ESignAgreement />} />
            </Route>
            <Route
              path="payment-status/:status/:id"
              element={
                <PrivateRoute>
                  <ChitsPaymentStatus />
                </PrivateRoute>
              }
            />

            {/* This matches with all routes that are not defined above */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
        <Analytics />
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App

import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { RazorpayOrderRequest, payFor } from './payment.models'
import { createRazorpayOrder, getPreferredGateway } from './paymentApiService'
import { PreferredGatewayState, RazorpayOrderState } from './payment.states'

export const preferredGatewayEvent = createAsyncThunk(
  'payment/getPaymentGateway',
  async (payFor: payFor): Promise<PreferredGatewayState> => {
    const res = await getPreferredGateway(payFor)
    return res.status.toLowerCase() === 'success'
      ? { response: 'success', data: res }
      : { response: 'failure' }
  }
)

export const razorpayInitial = createAction('payment/initial', () => {
  return {
    payload: {},
  }
})

export const razorpayOrderEvent = createAsyncThunk(
  'payment/razorpay',
  async (props: RazorpayOrderRequest): Promise<RazorpayOrderState> => {
    const res = await createRazorpayOrder(props)
    return res.status ?? false
      ? { response: 'success', data: res.data }
      : { response: 'failure' }
  }
)

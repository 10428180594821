import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import {
  generateAadhaarOTP,
  getKYCDetails,
  getPincode,
  initiateAadhaar,
  postKycBasicDetails,
  resendAadhaarCaptcha,
  saveCurrentAddress,
  saveNomineeDetails,
  verifyAadhaarOTP,
} from './kycApiService'
import {
  InitiateAadhaarState,
  KycState,
  PincodeState,
  VerifyAadhaarOtpState,
} from './kyc.states'
import {
  AddCurrentAddressRequest,
  GenerateAadhaarOTPRequest,
  KYCBasicDetailsRequest,
  NomineeRequest,
} from './kyc.models'
import { ApiResponse } from '../../common/common.states'

export const loadKycDetailsEvent = createAsyncThunk(
  'chitsKyc/get',
  async (): Promise<KycState> => {
    const res = await getKYCDetails()
    return res.status
      ? { response: 'success', data: res.data, chitsData: res.chitsData }
      : { response: 'failure' }
  }
)

export const kycBasicDetailsEvent = createAsyncThunk(
  'chitsKyc/postBasicDetails',
  async (props: KYCBasicDetailsRequest) => {
    const res = await postKycBasicDetails(props)
    return res.status ? 'success' : 'failure'
  }
)

export const initiateAadhaarEvent = createAsyncThunk(
  'chitsKyc/aadhaar',
  async (): Promise<InitiateAadhaarState> => {
    const res = await initiateAadhaar()
    return res.status?.toUpperCase() === 'SUCCESS'
      ? { response: 'success', captchaImage: res.data?.captchaImage }
      : { response: 'failure' }
  }
)

export const resendCaptchaEvent = createAsyncThunk(
  'chitsKyc/resendCaptcha',
  async (): Promise<InitiateAadhaarState> => {
    const res = await resendAadhaarCaptcha()
    return res.status?.toUpperCase() === 'SUCCESS'
      ? { response: 'success', captchaImage: res.data?.captchaImage }
      : { response: 'failure' }
  }
)

export const generateAadhaarOTPEvent = createAsyncThunk(
  'chitsKyc/generateOtp',
  async (props: GenerateAadhaarOTPRequest): Promise<ApiResponse> => {
    const res = await generateAadhaarOTP(props)
    return res.status?.toUpperCase() === 'SUCCESS'
      ? { response: 'success', message: res.message }
      : { response: 'failure', message: res.message ?? 'Something went wrong' }
  }
)

export const verifyAadhaarOtpEvent = createAsyncThunk(
  'chitsKyc/verifyOtp',
  async (otp: string): Promise<VerifyAadhaarOtpState> => {
    const res = await verifyAadhaarOTP(otp)
    return res.status?.toUpperCase() === 'SUCCESS'
      ? { response: 'success', data: res.Data }
      : { response: 'failure' }
  }
)

export const getPincodeEvent = createAsyncThunk(
  'chitsKyc/getPincode',
  async (): Promise<PincodeState> => {
    const res = await getPincode()
    return res.status
      ? {
          response: 'success',
          pinCode: res.pinCode,
          district: res.district,
          state: res.state,
        }
      : { response: 'failure' }
  }
)

export const updateCurrentAddressEvent = createAction(
  'chitsKyc/updateAddress',
  () => {
    return {
      payload: {},
    }
  }
)

export const saveCurrentAddressEvent = createAsyncThunk(
  'chitsKyc/saveAddress',
  async (props: AddCurrentAddressRequest): Promise<ApiResponse> => {
    const res = await saveCurrentAddress(props)
    return res.status
      ? { response: 'success', message: res.message }
      : { response: 'failure' }
  }
)

export const saveNomineeDetailsEvent = createAsyncThunk(
  'chitsKyc/saveNominee',
  async (props: NomineeRequest): Promise<ApiResponse> => {
    const res = await saveNomineeDetails(props)
    return res.status
      ? { response: 'success', message: res.message }
      : { response: 'failure' }
  }
)

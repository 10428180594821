import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getChitPlansPublic } from '../../../core/api/apiService'
import { ChitPlansResponse } from '../chitForm/chitFormSlice.models'

export interface ChitPlansState {
  status: 'loading' | 'idle'
  entities: ChitPlansResponse['groupList']
  error?: string
}

export const fetchChitPlans = createAsyncThunk('chit/getPlans', async () => {
  const res = await getChitPlansPublic()
  if (res.status) {
    return res.groupList
  } else {
    return []
  }
})

const initialState: ChitPlansState = {
  status: 'loading',
  entities: [],
}

export const chitPlansSlice = createSlice({
  name: 'chit',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchChitPlans.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(fetchChitPlans.fulfilled, (state, { payload }) => {
      state.status = 'idle'
      state.entities = payload ?? []
    })
    builder.addCase(fetchChitPlans.rejected, (state) => {
      state.status = 'idle'
    })
  },
})

export const chitPlansReducer = chitPlansSlice.reducer

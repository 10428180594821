import { axiosClient } from '../../../core/api/apiSetup'
import { EndPoints } from '../../../core/api/endPoints'
import { getAccessToken } from '../../common/helperFunctions'
import {
  ChitsDashboardResponse,
  ChitGroupsResponse,
  CheckEligibilityRequest,
  EligibleChitGroupResponse,
  InterestedUserRequest,
  ChitsRewardResponse,
  TransactionInfoResponse,
} from './chitsDashboard.models'

export async function getChitsDashboard() {
  try {
    axiosClient.defaults.headers.common = {
      Authorization: getAccessToken(),
    }
    const res = await axiosClient.get(EndPoints.chitsDashboard)
    console.log('getChitsDashboard => ', res.data)
    return res.data as ChitsDashboardResponse
  } catch (error) {
    console.error(error)
    return { status: false, message: 'error' }
  }
}

export async function getChitGroups(): Promise<ChitGroupsResponse> {
  try {
    axiosClient.defaults.headers.common = {
      Authorization: getAccessToken(),
    }
    const res = await axiosClient.get(EndPoints.getChitGroupsV2)
    console.log('getChitGroups => ', res.data)
    return res.data as ChitGroupsResponse
  } catch (error) {
    console.error(error)
    return { status: 'false', message: 'error' }
  }
}

export async function interestedChitsUser(props: InterestedUserRequest) {
  try {
    axiosClient.defaults.headers.common = {
      Authorization: getAccessToken(),
    }
    const res = await axiosClient.post(EndPoints.interestedUser, {
      groupId: props.groupId,
      isDifferentGroupSuggested: props.isDifferentGroupSuggested,
    })
    console.log('interestedChitsUser => ', res.data)
    return res.data as { status: boolean; message: string }
  } catch (error) {
    console.error(error)
    return { status: false, message: 'error' }
  }
}

export async function validatePan(pan: string) {
  try {
    axiosClient.defaults.headers.common = {
      Authorization: getAccessToken(),
    }
    const res = await axiosClient.post(EndPoints.panValidate, {
      consent: 'true',
      panNum: pan,
    })
    console.log('validatePan => ', res.data)
    return res.data as { status: string; KycResult?: { fullName?: string } }
  } catch (error) {
    console.error(error)
    return { status: 'failure' }
  }
}

export async function checkEligibility(props: CheckEligibilityRequest) {
  try {
    axiosClient.defaults.headers.common = {
      Authorization: getAccessToken(),
    }
    const res = await axiosClient.post(EndPoints.checkEligibility, {
      panNumber: props.panNumber,
      pinCode: props.pinCode,
      monthlyIncome: props.monthlyIncome,
      emailId: props.emailId,
      goal: props.goal,
      deviceId: `Website - ${navigator.userAgent}`,
    })
    console.log('checkEligibility =>', res.data)
    return res.data as {
      status: boolean
      message?: string
      invalidPin?: boolean
    }
  } catch (error) {
    console.error(error)
    return { status: false }
  }
}

export async function getEligibleChitGroups() {
  try {
    axiosClient.defaults.headers.common = {
      Authorization: getAccessToken(),
    }
    const res = await axiosClient.get(EndPoints.getEligibleChitGroupsV2)
    console.log('getEligibleChitGroups =>', res.data)
    return res.data as EligibleChitGroupResponse
  } catch (error) {
    console.error(error)
    return { status: false }
  }
}

export async function getChitsReward() {
  try {
    axiosClient.defaults.headers.common = {
      Authorization: getAccessToken(),
    }
    const res = await axiosClient.get(EndPoints.getChitReward)
    console.log('getChitsReward =>', res.data)
    return res.data as ChitsRewardResponse
  } catch (error) {
    console.error('getChitsReward =>', error)
    return { status: false, message: 'Something went wrong' }
  }
}

export async function getTransactionInfo(merchantId: string) {
  try {
    axiosClient.defaults.headers.common = {
      Authorization: getAccessToken(),
    }
    const res = await axiosClient.get(EndPoints.orderInfo, {
      params: { merchant_id: merchantId },
    })
    console.log('getTransactionInfo =>', res.data)
    return res.data as TransactionInfoResponse
  } catch (error) {
    console.error('getTransactionInfo =>', error)
    return { status: false, message: 'Something went wrong' }
  }
}

import { Box, Button, CircularProgress, Paper, Typography } from '@mui/material'
import { SimpleFooter } from './footer/Footer'
import Header from './header/Header'
import { useNavigate } from 'react-router-dom'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'

export default function CommonPage(props: {
  children: JSX.Element | JSX.Element[]
  className?: string
}) {
  return (
    <Box
      className={props.className}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        justifyContent: 'space-between',
        overflowY: 'scroll',
      }}
    >
      <Header showHeaderItems={false} />
      {props.children}
      <SimpleFooter />
    </Box>
  )
}

export function CommonLoader() {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress aria-busy sx={{ color: '#6027d0' }} />
    </Box>
  )
}

export function WholeScreenLoader() {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress aria-busy sx={{ color: '#6027d0' }} />
    </Box>
  )
}

export function SomethingWentWrong(props: {
  retryFunction: Function
  goBack?: boolean
  goTo?: string
  title?: string
  description?: string
}) {
  const navigate = useNavigate()
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Paper
        sx={{
          padding: '24px',
          textAlign: 'center',
          margin: '0 24px',
        }}
      >
        <Typography
          sx={{ fontSize: '24px', fontWeight: 'bold', fontFamily: 'inherit' }}
        >
          {props.title ?? 'Something went wrong'}
        </Typography>
        <Typography
          sx={{ fontSize: '14px', padding: '14px 0', fontFamily: 'inherit' }}
        >
          {props.description ??
            "It's not you, It's us. Something went wrong on our side."}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            disableTouchRipple
            variant="text"
            sx={{
              textTransform: 'none',
              fontSize: '16px',
            }}
            startIcon={<ArrowBackIosNewIcon fontSize="small" />}
            onClick={() => {
              if (props.goBack === true) {
                navigate(-1)
              } else if (props.goTo !== undefined) {
                navigate(props.goTo, { replace: true })
              } else {
                navigate('/', { replace: true })
              }
            }}
          >
            Back
          </Button>
          <Button
            disableTouchRipple
            variant="contained"
            sx={{
              textTransform: 'none',
              fontSize: '16px',
              '&.MuiButton-contained': {
                borderRadius: '8px',
              },
            }}
            onClick={() => props.retryFunction()}
          >
            Retry
          </Button>
        </Box>
      </Paper>
    </Box>
  )
}

import {
  AnyAction,
  Reducer,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit'
import { chitFormReducer } from '../../components/homePage/chitForm/chitFormSlice'
import { chitPlansReducer } from '../../components/homePage/chitPlans/chitPlansSlice'
import { emailSubscribeReducer } from '../../components/homePage/emailSubscribe/emailSubscribeSlice'
import { chitsDashboardReducer } from '../../components/chitsDashboard/chitsDashboardSlice/chitsDashboardSlice'
import { kycReducer } from '../../components/chitsDashboard/kycSlice/kycSlice'
import { paymentReducer } from '../../components/payment/paymentSlice'

// Combining all the reducers into one
const combinedReducers = combineReducers({
  chitPlans: chitPlansReducer,
  emailSubscribe: emailSubscribeReducer,
  chitForm: chitFormReducer,
  chitsDashboard: chitsDashboardReducer,
  chitsKyc: kycReducer,
  payment: paymentReducer,
})

export type AppState = ReturnType<typeof combinedReducers>

const rootReducer: Reducer = (state: AppState, action: AnyAction) => {
  if (action.type === 'RESET_STORE') {
    // eslint-disable-next-line
    state = {} as AppState
  }
  return combinedReducers(state, action)
}

export const store = configureStore({
  reducer: rootReducer,
})

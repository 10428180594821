import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { addEmail } from '../../../core/api/apiService'

interface EmailSubscribeState {
  status: 'idle' | 'loading'
  response?: 'success' | 'exists' | 'error'
}

export const addEmailSubscribe = createAsyncThunk(
  'emailSubscribe/post',
  async (email: string) => {
    const res = await addEmail(email)
    if (res.status) {
      return 'success'
    } else if (res.message.includes('already exists')) {
      return 'exists'
    } else {
      return 'error'
    }
  }
)

const initialState: EmailSubscribeState = {
  status: 'idle',
}

export const emailSubscribeSlice = createSlice({
  name: 'emailSubscribe',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addEmailSubscribe.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(addEmailSubscribe.fulfilled, (state, { payload }) => {
      state.status = 'idle'
      state.response = payload
    })
    builder.addCase(addEmailSubscribe.rejected, (state) => {
      state.status = 'idle'
      state.response = 'error'
    })
  },
})

export const emailSubscribeReducer = emailSubscribeSlice.reducer

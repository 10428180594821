import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { registerUser, saveLead, verifyOtp } from '../../../core/api/apiService'
import { RegisterUserRequest, OtpVerifyRequest } from './chitFormSlice.models'

interface RegisterUserState {
  status: 'initial' | 'idle' | 'loading'
  response?: 'success' | 'failure' | 'exists'
  mobileNumber: string
  name?: string
  state?: string
  whatsappConsent?: boolean
}

interface ConnectExpertState {
  status: 'idle' | 'loading'
  response?: 'success' | 'failure'
}

interface VerifyOTPState {
  status: 'initial' | 'idle' | 'loading'
  response?: 'success' | 'failure' | 'error'
  token: string
}

export const userOnboarding = createAsyncThunk(
  'chitForm/register',
  async (
    props: RegisterUserRequest
  ): Promise<{
    response: 'success' | 'failure' | 'exists'
    mobileNumber: string
    name?: string
    state?: string
    whatsappConsent?: boolean
  }> => {
    const res = await registerUser(props)
    if (res.status) {
      return {
        response: 'success',
        mobileNumber: props.mobileNumber,
        name: props.name,
        state: props.state,
        whatsappConsent: props.whatsappConsent,
      }
    } else if (res.message.includes('exisiting user')) {
      return {
        response: 'exists',
        mobileNumber: props.mobileNumber,
      }
    } else {
      return {
        response: 'failure',
        mobileNumber: props.mobileNumber,
        name: props.name,
        state: props.state,
        whatsappConsent: props.whatsappConsent,
      }
    }
  }
)
export const setInitialRegisterState = createAction(
  'chitForm/initialRegister',
  () => {
    return {
      payload: {},
    }
  }
)

export const saveChitFormLead = createAsyncThunk(
  'chitForm/saveLead',
  async (props: {
    mobileNumber: string
    name: string
    whatsappConsent: boolean
  }) => {
    const res = await saveLead(props)
    return res.status ? 'success' : 'failure'
  }
)

export const verifyOtpEvent = createAsyncThunk(
  'chitForm/verifyOtp',
  async (
    props: OtpVerifyRequest
  ): Promise<{
    verification: 'success' | 'failure'
    token: string
  }> => {
    const res = await verifyOtp(props)
    return res.status
      ? { verification: 'success', token: res.token }
      : { verification: 'failure', token: '' }
  }
)

export const setInitialOTPState = createAction('chitForm/initialOtp', () => {
  return {
    payload: {},
  }
})

const registerUserState: RegisterUserState = {
  status: 'initial',
  mobileNumber: '',
}
const connectExpertState: ConnectExpertState = { status: 'idle' }
const verifyOTPState: VerifyOTPState = { status: 'initial', token: '' }

const initialState = {
  registerUserState,
  connectExpertState,
  verifyOTPState,
}

export const chitFormSlice = createSlice({
  name: 'chitForm',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(userOnboarding.pending, (state) => {
      state.registerUserState.status = 'loading'
    })
    builder.addCase(userOnboarding.fulfilled, (state, { payload }) => {
      state.registerUserState.status = 'idle'
      state.registerUserState.response = payload.response
      state.registerUserState.mobileNumber = payload.mobileNumber
      state.registerUserState.name = payload.name
      state.registerUserState.state = payload.state
      state.registerUserState.whatsappConsent = payload.whatsappConsent
    })
    builder.addCase(userOnboarding.rejected, (state) => {
      state.registerUserState.status = 'idle'
      state.registerUserState.response = 'failure'
    })
    builder.addCase(setInitialRegisterState, (state) => {
      state.registerUserState.status = 'initial'
    })

    builder.addCase(saveChitFormLead.pending, (state) => {
      state.connectExpertState.status = 'loading'
    })
    builder.addCase(saveChitFormLead.fulfilled, (state, { payload }) => {
      state.connectExpertState.status = 'idle'
      state.connectExpertState.response = payload
    })
    builder.addCase(saveChitFormLead.rejected, (state) => {
      state.connectExpertState.status = 'idle'
      state.connectExpertState.response = 'failure'
    })

    builder.addCase(verifyOtpEvent.pending, (state) => {
      state.verifyOTPState.status = 'loading'
    })
    builder.addCase(verifyOtpEvent.fulfilled, (state, { payload }) => {
      state.verifyOTPState.status = 'idle'
      state.verifyOTPState.response = payload.verification
      state.verifyOTPState.token = payload.token
    })
    builder.addCase(verifyOtpEvent.rejected, (state) => {
      state.verifyOTPState.status = 'idle'
      state.verifyOTPState.response = 'error'
    })
    builder.addCase(setInitialOTPState, (state) => {
      state.verifyOTPState.status = 'initial'
    })
  },
})

export const chitFormReducer = chitFormSlice.reducer

import React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import SiplyChitsLogo from '../../../assets/images/siply_chits_logo.png'
import Link from '@mui/material/Link'
import './header.css'
import { useSelector } from 'react-redux'
import { AppState } from '../../../core/store/store'
import Drawer from '@mui/material/Drawer'
import DownloadApp from '../../homePage/downloadApp/DownloadApp'

function HeaderItem(props: { href?: string; text?: string }) {
  return (
    <div className="header-item">
      <a href={props.href}>{props.text}</a>
    </div>
  )
}

function Header(props: { showHeaderItems: boolean }) {
  const [state, setState] = React.useState<true | false>(false)

  const toggleDrawer = () => {
    setState(!state)
  }

  const { status, entities } = useSelector((state: AppState) => state.chitPlans)

  const list = () => (
    <Box
      role="presentation"
      onClick={toggleDrawer}
      onKeyDown={toggleDrawer}
      sx={{ width: 'auto', p: 3 }}
    >
      <div className="Drawer-Close">
        <Box
          component="img"
          sx={{ height: 40, width: 180 }}
          alt="siply chits logo"
          src={SiplyChitsLogo}
        />
        <Link onClick={toggleDrawer}>Close</Link>
      </div>
      <div className="Nav-Items">
        <HeaderItem href="#home" text="Home" />
        {status !== 'loading' &&
          entities !== undefined &&
          entities.length > 0 && (
            <HeaderItem href="#chit-plans" text="Chit Plans" />
          )}
        <HeaderItem href="#certificate" text="Certificate" />
        <HeaderItem href="#how-to-join" text="How to join" />
        <HeaderItem href="#testimonials" text="Testimonials" />
        <HeaderItem href="#faq" text="FAQ" />
        <DownloadApp hasBackground={true} addMargin={false} />
      </div>
    </Box>
  )

  return (
    <AppBar
      className="Header"
      elevation={3}
      position="sticky"
      sx={{
        backgroundColor: 'white',
      }}
    >
      <Container maxWidth="xl" sx={{ justifyContent: 'space-evenly' }}>
        <Toolbar disableGutters>
          <Box
            component="img"
            sx={{ height: '100%', width: '180px', py: '16px' }}
            alt="siply chits logo"
            src={SiplyChitsLogo}
          />
          {props.showHeaderItems && (
            <Box
              justifyContent="flex-end"
              sx={{
                flexGrow: 1,
                display: { xs: 'none', md: 'flex' },
                alignItems: 'center',
              }}
            >
              <HeaderItem href="#home" text="Home" />
              {status !== 'loading' &&
                entities !== undefined &&
                entities.length > 0 && (
                  <HeaderItem href="#chit-plans" text="Chit Plans" />
                )}
              <HeaderItem href="#certificate" text="Certificate" />
              <HeaderItem href="#how-to-join" text="How to join" />
              <HeaderItem href="#testimonials" text="Testimonials" />
              <HeaderItem href="#faq" text="FAQ" />
              <Button
                disableRipple
                disableFocusRipple
                disableTouchRipple
                disableElevation
                variant="contained"
                href="#download-app"
                sx={{
                  textTransform: 'none',
                  marginLeft: 2,
                  borderRadius: 20,
                  px: 2,
                  height: 40,
                  fontSize: '16px',
                  '& .MuiButton-contained:active, :focus': {
                    color: '#fff',
                  },
                }}
              >
                Download App
              </Button>
            </Box>
          )}

          {/* Mobile Menu */}
          {props.showHeaderItems && (
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'flex', md: 'none' },
                justifyContent: 'flex-end',
              }}
            >
              <IconButton
                size="large"
                aria-label="menu"
                aria-haspopup="true"
                onClick={toggleDrawer}
                color="primary"
              >
                <MenuIcon />
              </IconButton>
            </Box>
          )}
          <Drawer anchor="top" open={state} onClose={toggleDrawer}>
            {list()}
          </Drawer>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default Header

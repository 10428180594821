import { axiosSiplyClient, axiosClient } from '../../core/api/apiSetup'
import { EndPoints, SiplyEndPoints } from '../../core/api/endPoints'
import { getAccessToken } from '../common/helperFunctions'
import {
  PreferredGatewayResponse,
  RazorpayOrderRequest,
  RazorpayOrderResponse,
  payFor,
} from './payment.models'

export async function getPreferredGateway(
  payFor: payFor
): Promise<PreferredGatewayResponse> {
  try {
    axiosSiplyClient.defaults.headers.common = {
      Authorization: getAccessToken(),
    }
    const res = await axiosSiplyClient.get(
      SiplyEndPoints.paymentConfiguration,
      {
        params: { paymentType: payFor },
      }
    )
    console.log('getPreferredGateway =>', res.data)
    return res.data as PreferredGatewayResponse
  } catch (error) {
    console.error('getPreferredGateway =>', error)
    return { status: 'failure' }
  }
}

export async function createRazorpayOrder(
  props: RazorpayOrderRequest
): Promise<RazorpayOrderResponse> {
  try {
    axiosClient.defaults.headers.common = {
      Authorization: getAccessToken(),
    }
    const res = await axiosClient.post(EndPoints.createRazorpayOrder, props)
    console.log('createRazorpayOrder =>', res.data)
    return res.data as RazorpayOrderResponse
  } catch (error) {
    console.error('createRazorpayOrder =>', error)
    return { status: false, message: 'Something went wrong. Please try again.' }
  }
}

import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import {
  getChitGroups,
  getChitsDashboard,
  interestedChitsUser,
  validatePan,
  checkEligibility,
  getEligibleChitGroups,
  getChitsReward,
  getTransactionInfo,
} from './chitsApiService'
import {
  CheckEligibilityState,
  ChitGroupsState,
  ChitsDashboardState,
  ChitsRewardState,
  EligibleChitGroupsState,
  InterestedUserState,
  TransactionInfoState,
  ValidatePanState,
} from './chitsDashboard.states'
import {
  CheckEligibilityRequest,
  InterestedUserRequest,
} from './chitsDashboard.models'

// Events
export const getChitGroupsEvent = createAsyncThunk(
  'chitGroups/getChits',
  async (): Promise<ChitGroupsState> => {
    const res = await getChitGroups()
    return res.status === 'success'
      ? { response: 'success', groups: res.data }
      : { response: 'failure' }
  }
)

export const getChitsDashboardEvent = createAsyncThunk(
  'chitsDashboard/get',
  async (): Promise<ChitsDashboardState> => {
    const res = await getChitsDashboard()
    let returnData = {}
    if (res.status ?? false) {
      returnData = {
        response: 'success',
        data: {
          chitEnrollUser: res.chitEnrollUser,
          kycInProcess: res.kycInProcess,
          isEligible: res.isEligible,
          chitPlans: res.data,
        },
      }
    } else {
      returnData = { response: 'failure' }
    }
    return returnData
  }
)

export const refreshDashboardForESignEvent = createAsyncThunk(
  'chitsDashboard/refreshForEsign',
  async (): Promise<ChitsDashboardState> => {
    const res = await getChitsDashboard()
    let returnData = {}
    if (res.status ?? false) {
      returnData = {
        response: 'success',
        data: {
          chitEnrollUser: res.chitEnrollUser,
          kycInProcess: res.kycInProcess,
          isEligible: res.isEligible,
          chitPlans: res.data,
        },
      }
    } else {
      returnData = { response: 'failure' }
    }
    return returnData
  }
)

export const interestedUserInitial = createAction(
  'chitsDashboard/interestedUserInitial',
  () => {
    return {
      payload: {},
    }
  }
)

export const interestedUserEvent = createAsyncThunk(
  'chitsDashboard/interestedUser',
  async (props: InterestedUserRequest): Promise<InterestedUserState> => {
    const res = await interestedChitsUser(props)
    return res.status ? { response: 'success' } : { response: 'failure' }
  }
)

export const validatePanEvent = createAsyncThunk(
  'chitsDashboard/validatePAN',
  async (pan: string): Promise<ValidatePanState> => {
    const res = await validatePan(pan)
    return res.status.toLowerCase() === 'success' &&
      res.KycResult?.fullName !== undefined &&
      res.KycResult?.fullName.length > 0
      ? { response: 'success', fullName: res.KycResult?.fullName }
      : { response: 'failure' }
  }
)

export const checkEligibilityEvent = createAsyncThunk(
  'chitsDashboard/checkEligibility',
  async (props: CheckEligibilityRequest): Promise<CheckEligibilityState> => {
    const res = await checkEligibility(props)
    return res.status
      ? {
          response: 'success',
          invalidPin: res.invalidPin === true,
          message: res.message,
        }
      : { response: 'failure', invalidPin: false, message: res.message }
  }
)

export const getEligibleChitGroupsEvent = createAsyncThunk(
  'chitsDashboard/getEligibleChitGroups',
  async (): Promise<EligibleChitGroupsState> => {
    const res = await getEligibleChitGroups()
    return res.status
      ? {
          response: 'success',
          data: res.data,
          imageURL: res.imageURL,
          recentCustomer: res.recentCustomer,
          kycInProcess: res.kycInProcess,
          isSelectedGroupAvailable: res.isSelectedGroupAvailable,
        }
      : { response: 'failure' }
  }
)

export const updateSelectedChitEvent = createAction(
  'chitsDashboard/selectedChit',
  (chit?: string) => {
    return {
      payload: chit,
    }
  }
)

export const clearSelectedChitEvent = createAction(
  'chitsDashboard/clearSelectedChit',
  () => {
    return {
      payload: {},
    }
  }
)

export const getChitsRewardEvent = createAsyncThunk(
  'chitsDashboard/getChitsReward',
  async (): Promise<ChitsRewardState> => {
    const res = await getChitsReward()
    return res.status
      ? {
          response: 'success',
          rewardAmount: res.rewardAmount,
          timeLeft: res.timeLeft,
        }
      : { response: 'failure', message: res.message ?? 'Something went wrong' }
  }
)

export const getTransactionInfoEvent = createAsyncThunk(
  'chitsDashboard/orderInfo',
  async (merchantId: string): Promise<TransactionInfoState> => {
    const res = await getTransactionInfo(merchantId)
    return res.status
      ? {
          response: 'success',
          info: res.data,
        }
      : {
          response: 'failure',
        }
  }
)

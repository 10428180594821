import { Helmet } from 'react-helmet-async'
import { GA_TRACKING_ID, GTM_ID } from './events'

export function Analytics() {
  return (
    <>
      <Helmet>
        {/* Global site tag (gtag.js) - Google Analytics */}
        <script
          async
          src={'https://www.googletagmanager.com/gtag/js?id=' + GA_TRACKING_ID}
        />
        <script>
          {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${GA_TRACKING_ID}');
        `}
        </script>
        {/* Google Tag Manager */}
        <script>
          {`(function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({
              'gtm.start': new Date().getTime(),
              event: 'gtm.js'
            });
            var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer', '${GTM_ID}');
        `}
        </script>
        {/* End Google Tag Manager */}
      </Helmet>
    </>
  )
}

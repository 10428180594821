import axios from 'axios'
import { Links } from './endPoints'

const API_TIMEOUT = 30000
const HEADERS = { 'content-type': 'application/json' }

export const axiosClient = axios.create({
  baseURL: Links.PROD_URL,
  timeout: API_TIMEOUT,
  headers: { ...HEADERS },
})

export const axiosSiplyClient = axios.create({
  baseURL: Links.SIPLY_PROD_URL,
  timeout: API_TIMEOUT,
  headers: { ...HEADERS },
})

import { createSlice } from '@reduxjs/toolkit'
import {
  CheckEligibilityState,
  ChitGroupsState,
  ChitsDashboardState,
  ChitsRewardState,
  EligibleChitGroupsState,
  InterestedUserState,
  TransactionInfoState,
} from './chitsDashboard.states'
import {
  getChitGroupsEvent,
  getChitsDashboardEvent,
  interestedUserEvent,
  validatePanEvent,
  checkEligibilityEvent,
  getEligibleChitGroupsEvent,
  getChitsRewardEvent,
  updateSelectedChitEvent,
  getTransactionInfoEvent,
  clearSelectedChitEvent,
  interestedUserInitial,
  refreshDashboardForESignEvent,
} from './chitsDashboard.events'
import { ApiStatus } from '../../common/common.states'

// Initial states
const groupsState: ApiStatus & ChitGroupsState = { status: 'initial' }
const dashboardState: ApiStatus & ChitsDashboardState = { status: 'initial' }
const dashboardStateForEsign: ApiStatus & ChitsDashboardState = {
  status: 'initial',
}
const interestedUserState: ApiStatus & InterestedUserState = {
  status: 'initial',
}
const validatePanState: ApiStatus & {
  response?: 'success' | 'failure'
  fullName?: string
} = {
  status: 'initial',
}
const checkEligibilityState: ApiStatus & CheckEligibilityState = {
  status: 'initial',
}
const eligibleChitGroupsState: ApiStatus & EligibleChitGroupsState = {
  status: 'initial',
}
const chitsRewardState: ApiStatus & ChitsRewardState = { status: 'initial' }
const transactionInfoState: ApiStatus & TransactionInfoState = {
  status: 'initial',
}

// slice
export const chitsDashboardSlice = createSlice({
  name: 'chitsDashboard',
  initialState: {
    groupsState,
    dashboardState,
    dashboardStateForEsign,
    interestedUserState,
    validatePanState,
    checkEligibilityState,
    eligibleChitGroupsState,
    chitsRewardState,
    transactionInfoState,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getChitGroupsEvent.pending, (state) => {
      state.groupsState.status = 'loading'
      state.groupsState.response = undefined
    })
    builder.addCase(getChitGroupsEvent.fulfilled, (state, { payload }) => {
      state.groupsState.status = 'idle'
      state.groupsState.response = payload.response
      state.groupsState.groups = payload.groups
    })
    builder.addCase(getChitGroupsEvent.rejected, (state) => {
      state.groupsState.status = 'idle'
      state.groupsState.response = 'failure'
    })

    builder.addCase(getChitsDashboardEvent.pending, (state) => {
      state.dashboardState.status = 'loading'
      state.dashboardStateForEsign.status = 'loading'
    })
    builder.addCase(getChitsDashboardEvent.fulfilled, (state, { payload }) => {
      state.dashboardState.status = 'idle'
      state.dashboardState.response = payload.response
      state.dashboardState.data = payload.data
      state.dashboardStateForEsign.status = 'idle'
      state.dashboardStateForEsign.response = payload.response
      state.dashboardStateForEsign.data = payload.data
    })
    builder.addCase(getChitsDashboardEvent.rejected, (state) => {
      state.dashboardState.status = 'idle'
      state.dashboardState.response = 'failure'
      state.dashboardStateForEsign.status = 'idle'
      state.dashboardStateForEsign.response = 'failure'
    })

    builder.addCase(refreshDashboardForESignEvent.pending, (state) => {
      state.dashboardStateForEsign.status = 'loading'
    })
    builder.addCase(
      refreshDashboardForESignEvent.fulfilled,
      (state, { payload }) => {
        state.dashboardStateForEsign.status = 'idle'
        state.dashboardStateForEsign.response = payload.response
        state.dashboardStateForEsign.data = payload.data
      }
    )
    builder.addCase(refreshDashboardForESignEvent.rejected, (state) => {
      state.dashboardStateForEsign.status = 'idle'
      state.dashboardStateForEsign.response = 'failure'
    })

    builder.addCase(interestedUserInitial, (state) => {
      state.interestedUserState.status = 'initial'
    })
    builder.addCase(interestedUserEvent.pending, (state) => {
      state.interestedUserState.status = 'loading'
    })
    builder.addCase(interestedUserEvent.fulfilled, (state, { payload }) => {
      state.interestedUserState.status = 'idle'
      state.interestedUserState.response = payload.response
    })
    builder.addCase(interestedUserEvent.rejected, (state) => {
      state.interestedUserState.status = 'idle'
      state.interestedUserState.response = 'failure'
    })

    builder.addCase(validatePanEvent.pending, (state) => {
      state.validatePanState.status = 'loading'
    })
    builder.addCase(validatePanEvent.fulfilled, (state, { payload }) => {
      state.validatePanState.status = 'idle'
      state.validatePanState.response = payload.response
      state.validatePanState.fullName = payload.fullName
    })
    builder.addCase(validatePanEvent.rejected, (state) => {
      state.validatePanState.status = 'idle'
      state.validatePanState.response = 'failure'
    })

    builder.addCase(checkEligibilityEvent.pending, (state) => {
      state.checkEligibilityState.status = 'loading'
    })
    builder.addCase(checkEligibilityEvent.fulfilled, (state, { payload }) => {
      state.checkEligibilityState.status = 'idle'
      state.checkEligibilityState.response = payload.response
      state.checkEligibilityState.message = payload.message
      state.checkEligibilityState.invalidPin = payload.invalidPin
    })
    builder.addCase(checkEligibilityEvent.rejected, (state) => {
      state.checkEligibilityState.status = 'idle'
      state.checkEligibilityState.response = 'failure'
    })

    builder.addCase(getEligibleChitGroupsEvent.pending, (state) => {
      state.eligibleChitGroupsState.status = 'loading'
    })
    builder.addCase(
      getEligibleChitGroupsEvent.fulfilled,
      (state, { payload }) => {
        state.eligibleChitGroupsState.status = 'idle'
        state.eligibleChitGroupsState.response = payload.response
        state.eligibleChitGroupsState.data = payload.data
        state.eligibleChitGroupsState.imageURL = payload.imageURL
        state.eligibleChitGroupsState.recentCustomer = payload.recentCustomer
        state.eligibleChitGroupsState.kycInProcess = payload.kycInProcess
        state.eligibleChitGroupsState.isSelectedGroupAvailable =
          payload.isSelectedGroupAvailable
      }
    )
    builder.addCase(getEligibleChitGroupsEvent.rejected, (state) => {
      state.eligibleChitGroupsState.status = 'idle'
      state.eligibleChitGroupsState.response = 'failure'
    })
    builder.addCase(updateSelectedChitEvent, (state, { payload }) => {
      state.eligibleChitGroupsState.selectedChit = payload
    })
    builder.addCase(clearSelectedChitEvent, (state) => {
      state.eligibleChitGroupsState.selectedChit = undefined
    })

    builder.addCase(getChitsRewardEvent.pending, (state) => {
      state.chitsRewardState.status = 'loading'
    })
    builder.addCase(getChitsRewardEvent.fulfilled, (state, { payload }) => {
      state.chitsRewardState.status = 'idle'
      state.chitsRewardState.response = payload.response
      state.chitsRewardState.message = payload.message
      state.chitsRewardState.rewardAmount = payload.rewardAmount
      state.chitsRewardState.timeLeft = payload.timeLeft
    })
    builder.addCase(getChitsRewardEvent.rejected, (state) => {
      state.chitsRewardState.status = 'idle'
      state.chitsRewardState.response = 'failure'
    })

    builder.addCase(getTransactionInfoEvent.pending, (state) => {
      state.transactionInfoState.status = 'loading'
    })
    builder.addCase(getTransactionInfoEvent.fulfilled, (state, { payload }) => {
      state.transactionInfoState.status = 'idle'
      state.transactionInfoState.response = payload.response
      state.transactionInfoState.message = payload.message
      state.transactionInfoState.info = payload.info
    })
    builder.addCase(getTransactionInfoEvent.rejected, (state) => {
      state.transactionInfoState.status = 'idle'
      state.transactionInfoState.response = 'failure'
    })
  },
})

export const chitsDashboardReducer = chitsDashboardSlice.reducer

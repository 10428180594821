import { createSlice } from '@reduxjs/toolkit'
import { PreferredGatewayState, RazorpayOrderState } from './payment.states'
import { ApiStatus } from '../common/common.states'
import {
  razorpayOrderEvent,
  preferredGatewayEvent,
  razorpayInitial,
} from './payment.events'

// initial states
const preferredGatewayState: ApiStatus & PreferredGatewayState = {
  status: 'initial',
}
const razorpayOrderState: ApiStatus & RazorpayOrderState = { status: 'initial' }

export const paymentSlice = createSlice({
  name: 'payment',
  initialState: {
    preferredGatewayState,
    razorpayOrderState,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(preferredGatewayEvent.pending, (state) => {
      state.preferredGatewayState.status = 'loading'
    })
    builder.addCase(preferredGatewayEvent.fulfilled, (state, { payload }) => {
      state.preferredGatewayState.status = 'idle'
      state.preferredGatewayState.response = payload.response
      state.preferredGatewayState.data = payload.data
    })
    builder.addCase(preferredGatewayEvent.rejected, (state) => {
      state.preferredGatewayState.status = 'idle'
      state.preferredGatewayState.response = 'failure'
    })

    builder.addCase(razorpayInitial, (state) => {
      state.razorpayOrderState.status = 'initial'
    })
    builder.addCase(razorpayOrderEvent.pending, (state) => {
      state.razorpayOrderState.status = 'loading'
    })
    builder.addCase(razorpayOrderEvent.fulfilled, (state, { payload }) => {
      state.razorpayOrderState.status = 'idle'
      state.razorpayOrderState.response = payload.response
      state.razorpayOrderState.data = payload.data
    })
    builder.addCase(razorpayOrderEvent.rejected, (state) => {
      state.razorpayOrderState.status = 'idle'
      state.razorpayOrderState.response = 'failure'
    })
  },
})

export const paymentReducer = paymentSlice.reducer

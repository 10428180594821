import { createSlice } from '@reduxjs/toolkit'
import {
  loadKycDetailsEvent,
  kycBasicDetailsEvent,
  initiateAadhaarEvent,
  resendCaptchaEvent,
  verifyAadhaarOtpEvent,
  generateAadhaarOTPEvent,
  getPincodeEvent,
  saveCurrentAddressEvent,
  saveNomineeDetailsEvent,
  updateCurrentAddressEvent,
} from './kyc.events'
import {
  InitiateAadhaarState,
  KycState,
  PincodeState,
  VerifyAadhaarOtpState,
} from './kyc.states'
import { ApiResponse, ApiStatus } from '../../common/common.states'

// Initial states
const kycState: ApiStatus & KycState = { status: 'initial' }
const kycBasicDetailsState: ApiStatus & ApiResponse = { status: 'initial' }
const initiateAadhaarState: ApiStatus & InitiateAadhaarState = {
  status: 'initial',
}
const generateOtpState: ApiStatus & ApiResponse = {
  status: 'initial',
}
const verifyOtpState: ApiStatus & VerifyAadhaarOtpState = { status: 'initial' }
const pincodeState: ApiStatus & PincodeState = { status: 'initial' }
const currentAddressState: ApiStatus & ApiResponse = { status: 'initial' }
const saveNomineeState: ApiStatus & ApiResponse = { status: 'initial' }

export const kycSlice = createSlice({
  name: 'chitsKyc',
  initialState: {
    kycState,
    kycBasicDetailsState,
    initiateAadhaarState,
    generateOtpState,
    verifyOtpState,
    pincodeState,
    currentAddressState,
    saveNomineeState,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadKycDetailsEvent.pending, (state) => {
      state.kycState.status = 'loading'
    })
    builder.addCase(loadKycDetailsEvent.fulfilled, (state, { payload }) => {
      state.kycState.status = 'idle'
      state.kycState.response = payload.response
      state.kycState.data = payload.data
      state.kycState.chitsData = payload.chitsData
    })
    builder.addCase(loadKycDetailsEvent.rejected, (state) => {
      state.kycState.status = 'idle'
      state.kycState.response = 'failure'
    })

    builder.addCase(kycBasicDetailsEvent.pending, (state) => {
      state.kycBasicDetailsState.status = 'loading'
    })
    builder.addCase(kycBasicDetailsEvent.fulfilled, (state, { payload }) => {
      state.kycBasicDetailsState.status = 'idle'
      state.kycBasicDetailsState.response = payload
    })
    builder.addCase(kycBasicDetailsEvent.rejected, (state) => {
      state.kycBasicDetailsState.status = 'idle'
      state.kycBasicDetailsState.response = 'failure'
    })

    builder.addCase(initiateAadhaarEvent.pending, (state) => {
      state.initiateAadhaarState.status = 'loading'
    })
    builder.addCase(initiateAadhaarEvent.fulfilled, (state, { payload }) => {
      state.initiateAadhaarState.status = 'idle'
      state.initiateAadhaarState.response = payload.response
      state.initiateAadhaarState.captchaImage = payload.captchaImage
    })
    builder.addCase(initiateAadhaarEvent.rejected, (state) => {
      state.initiateAadhaarState.status = 'idle'
      state.initiateAadhaarState.response = 'failure'
    })

    builder.addCase(resendCaptchaEvent.pending, (state) => {
      state.initiateAadhaarState.status = 'loading'
    })
    builder.addCase(resendCaptchaEvent.fulfilled, (state, { payload }) => {
      state.initiateAadhaarState.status = 'idle'
      state.initiateAadhaarState.response = payload.response
      state.initiateAadhaarState.captchaImage = payload.captchaImage
    })
    builder.addCase(resendCaptchaEvent.rejected, (state) => {
      state.initiateAadhaarState.status = 'idle'
      state.initiateAadhaarState.response = 'failure'
    })

    builder.addCase(generateAadhaarOTPEvent.pending, (state) => {
      state.generateOtpState.status = 'loading'
    })
    builder.addCase(generateAadhaarOTPEvent.fulfilled, (state, { payload }) => {
      state.generateOtpState.status = 'idle'
      state.generateOtpState.response = payload.response
      state.generateOtpState.message = payload.message
    })
    builder.addCase(generateAadhaarOTPEvent.rejected, (state) => {
      state.generateOtpState.status = 'idle'
      state.generateOtpState.response = 'failure'
    })

    builder.addCase(verifyAadhaarOtpEvent.pending, (state) => {
      state.verifyOtpState.status = 'loading'
    })
    builder.addCase(verifyAadhaarOtpEvent.fulfilled, (state, { payload }) => {
      state.verifyOtpState.status = 'idle'
      state.verifyOtpState.response = payload.response
      state.verifyOtpState.data = payload.data
    })
    builder.addCase(verifyAadhaarOtpEvent.rejected, (state) => {
      state.verifyOtpState.status = 'idle'
      state.verifyOtpState.response = 'failure'
    })

    builder.addCase(getPincodeEvent.pending, (state) => {
      state.pincodeState.status = 'loading'
    })
    builder.addCase(getPincodeEvent.fulfilled, (state, { payload }) => {
      state.pincodeState.status = 'idle'
      state.pincodeState.response = payload.response
      state.pincodeState.district = payload.district
      state.pincodeState.state = payload.state
      state.pincodeState.pinCode = payload.pinCode
    })
    builder.addCase(getPincodeEvent.rejected, (state) => {
      state.pincodeState.status = 'idle'
      state.pincodeState.response = 'failure'
    })

    builder.addCase(saveCurrentAddressEvent.pending, (state) => {
      state.currentAddressState.status = 'loading'
    })
    builder.addCase(saveCurrentAddressEvent.fulfilled, (state, { payload }) => {
      state.currentAddressState.status = 'idle'
      state.currentAddressState.response = payload.response
      state.currentAddressState.message = payload.message
    })
    builder.addCase(saveCurrentAddressEvent.rejected, (state) => {
      state.currentAddressState.status = 'idle'
      state.currentAddressState.response = 'failure'
    })
    builder.addCase(updateCurrentAddressEvent, (state) => {
      state.currentAddressState.status = 'idle'
      state.currentAddressState.response = 'success'
    })

    builder.addCase(saveNomineeDetailsEvent.pending, (state) => {
      state.saveNomineeState.status = 'loading'
    })
    builder.addCase(saveNomineeDetailsEvent.fulfilled, (state, { payload }) => {
      state.saveNomineeState.status = 'idle'
      state.saveNomineeState.response = payload.response
      state.saveNomineeState.message = payload.message
    })
    builder.addCase(saveNomineeDetailsEvent.rejected, (state) => {
      state.saveNomineeState.status = 'idle'
      state.saveNomineeState.response = 'failure'
    })
  },
})

export const kycReducer = kycSlice.reducer

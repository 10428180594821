import './portfolio.css'
import Button from '@mui/material/Button'
import RefreshIcon from '@mui/icons-material/Refresh'
import { store } from '../../../core/store/store'
import { getChitsDashboardEvent } from '../chitsDashboardSlice/chitsDashboard.events'

export function Portfolio() {
  const handleRefresh = () => {
    void store.dispatch(getChitsDashboardEvent())
  }
  return (
    <Button
      disableTouchRipple
      disableRipple
      variant="contained"
      endIcon={<RefreshIcon fontSize="small" sx={{ color: '#fff' }} />}
      sx={{
        textTransform: 'none',
        fontSize: '16px',
        fontWeight: 700,
        p: '10px 48px',
        '&.MuiButton-contained': {
          borderRadius: '24px',
        },
        width: '160px',
        alignSelf: 'center',
      }}
      onClick={handleRefresh}
    >
      Refresh
    </Button>
  )
}

export const EndPoints = {
  saveLead: 'chitsWeb/api/v1/saveLead',
  getAllChits: 'chitsWeb/api/v1/allPlans',
  subscribeForOffers: 'chitsWeb/api/v1/chitsSubscription',
  chitsDashboard: 'chitFunds/api/v3/chitDashboard',
  getChitGroupsV2: 'chitFunds/api/v1/getChitsGroupsV2',
  interestedUser: 'chitKyc/api/v1/saveInterestedCustomerDetails',
  panValidate: 'chitKycNew/api/v1/panValidate',
  checkEligibility: 'chitKyc/api/v1/createCustomer',
  getEligibleChitGroupsV2: 'chitFunds/api/v1/getCustomerGroupEligibilityV2',
  postKycBasicDetails: 'chitKyc/api/v3/savePersonalDetails',
  getKycDetails: 'chitOCRKyc/api/v1/getKYCDetailsV2',
  initiateAadhaar: 'chitKycNew/api/v1/aadharInitiate',
  reloadCaptcha: 'chitKycNew/api/v1/aadharReload',
  generateAadhaarOTP: 'chitKycNew/api/v1/aadharGenerateOTP',
  validateAadhaarOTP: 'chitKycNew/api/v1/aadharValidateOTP',
  saveCurrentAddress: 'chitKyc/api/v1/saveCurrentAddressV2',
  getPincode: 'chitKyc/api/v1/getLocationFromPin',
  saveNomineeDetails: 'chitKyc/api/v1/saveNomineDetailsV2',
  getChitReward: '/chitFunds/api/v1/getCustomerReward',
  createRazorpayOrder: 'chitPayments/api/v2/createRazorpayOrderV2',
  orderInfo: 'chitPayments/api/v1/orderInfo',
}

export const SiplyEndPoints = {
  registerUser: 'onboarding/api/v1/loginOtpRegister',
  otpVerify: 'onboarding/api/v1/loginOtpVerify',
  paymentConfiguration: 'validate/api/v4/paymentConfiguration',
}

export const Links = {
  // base urls
  PROD_URL: 'https://prod-api.chits.siply.in/',
  STAGING_URL: 'https://preprod-api.chits.siply.in/',
  SIPLY_STAGING_URL: 'https://aws-east.siply.in/',
  SIPLY_PROD_URL: 'https://aws-south.siply.in/',
  // siply common links
  siplyWebsite: 'https://siply.in',
  siplyChitsWebsite: 'https://chits.siply.in',
  siplyAppStore:
    'https://apps.apple.com/in/app/siply-gold-chits-stocks/id1620535461',
  siplyPlayStore:
    'https://play.google.com/store/apps/details?id=in.siply.ionic',
  // siply social handles
  siplyLinkedIn: 'https://www.linkedin.com/company/siply-india',
  siplyTwitter: 'https://mobile.twitter.com/jiyosiply',
  siplyInstagram: 'https://www.instagram.com/jiyosiply',
  siplyFacebook: 'https://www.facebook.com/jiyosiply',
  // privacy policy & terms and conditions
  siplyPrivacyPolicy:
    'https://assets.siply.in/website/Siply_Privacy_Policy2.pdf',
  siplyTermsAndConditions: 'https://assets.siply.in/Siply_T&Cs.pdf',
  siplyRefundPolicy: 'https://assets.siply.in/Siply_Refund_Policy.pdf',
  siplyChitsTermsAndConditions:
    'https://assets.siply.in/chit_terms_and_condition/chits_terms_and_conditions.html',
  siplyChitsPrivacyPolicy:
    'https://assets.siply.in/chit_terms_and_condition/chits_privacy_policy.html',
  // siply in news
  economicTimes:
    'https://economictimes.indiatimes.com/tech/funding/microsavings-platform-siply-bags-19-million-funding-led-by-qi-ventures/articleshow/92205687.cms',
  yourStory:
    'https://yourstory.com/2022/03/fintech-startup-siply-helping-underserved-indians-save/amp',
  outlookIndia:
    'https://www.outlookindia.com/business-spotlight/siply-empowering-the-masses-financially-across-india-news-222291',
  theHinduBusinessLine:
    'https://www.thehindubusinessline.com/money-and-banking/siply-disburses-125-cr-loan-in-8-months-without-any-default/article65341349.ece',
}

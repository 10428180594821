import Typography from '@mui/material/Typography'
import SiplyLogo from '../../../assets/images/logo.svg'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import TwitterIcon from '@mui/icons-material/Twitter'
import './footer.css'
import { AccordionDetails, IconButton } from '@mui/material'
import { Accordion, AccordionSummary } from '../customElements/CustomElements'
import Divider from '../../../assets/images/fancy_divider.png'
import ESignImage from '../../../assets/images/e_chits.webp'
import GovenmentLicensedLogo from '../../../assets/images/government_logo.webp'
import { Links } from '../../../core/api/endPoints'

const socialMediaHandles = [
  {
    icon: <TwitterIcon fontSize="large" sx={{ color: '#6027d0' }} />,
    link: Links.siplyTwitter,
    label: 'Twitter handle of Siply',
  },
  {
    icon: <InstagramIcon fontSize="large" sx={{ color: '#6027d0' }} />,
    link: Links.siplyInstagram,
    label: 'Instagram handle of Siply',
  },
  {
    icon: <FacebookIcon fontSize="large" sx={{ color: '#6027d0' }} />,
    link: Links.siplyFacebook,
    label: 'Facebook page of Siply',
  },
  {
    icon: <LinkedInIcon fontSize="large" sx={{ color: '#6027d0' }} />,
    link: Links.siplyLinkedIn,
    label: 'Linked In of Siply',
  },
]

const policyLinks = [
  {
    text: 'Privacy Policy',
    link: Links.siplyPrivacyPolicy,
  },
  {
    text: 'Terms and Conditions',
    link: Links.siplyTermsAndConditions,
  },
  {
    text: 'Refund Policy',
    link: Links.siplyRefundPolicy,
  },
]

function Footer() {
  return (
    <footer className="Footer">
      <div className="Logo-Wrap">
        <div className="Logo-Address">
          <img src={SiplyLogo} alt="Siply Logo" />
          <span>
            <strong>Siply Services Private Limited,</strong>
            <br />
            L372, AVR Heights, 2nd Floor,
            <br /> 5th Main Rd, Sector 6, HSR Layout,
            <br />
            Bengaluru, Karnataka - 560102
          </span>
        </div>
        <div className="Products-Who-we-are">
          <div>
            Products
            <a href={Links.siplyWebsite} target="_blank" rel="noreferrer">
              Siply Gold
            </a>
            <a href={Links.siplyChitsWebsite}>Siply Chits</a>
          </div>
          <div>
            Who we are
            <a href={Links.siplyWebsite} target="_blank" rel="noreferrer">
              About
            </a>
            <a href={Links.siplyLinkedIn} target="_blank" rel="noreferrer">
              Careers
            </a>
            <a href="#press">Press</a>
          </div>
        </div>
        <div className="Social-Media">
          Follow us on
          <div className="Social-Links">
            {socialMediaHandles.map((item, index) => {
              return (
                <IconButton
                  key={index}
                  target="_blank"
                  href={item.link}
                  arai-label={item.label}
                  rel="noreferrer"
                  sx={{
                    backgroundColor: '#fff',
                    borderRadius: '5px',
                    m: 1,
                    p: 0.5,
                  }}
                >
                  {item.icon}
                </IconButton>
              )
            })}
          </div>
        </div>
      </div>
      <div className="More-About-Siply">
        <Typography
          sx={{
            display: { xs: 'none', md: 'block' },
            fontSize: '14px',
            color: '#464646',
            marginBottom: '24px',
            px: '12vw',
            fontFamily: 'inherit',
          }}
        >
          Siply is an Augmont & IBG eChits partnered, saving and borrowing app.
          In Siply Gold, you can buy 24K gold jewellery and coin starting at
          just ₹450/month. And can borrow upto ₹1,00,000 in Siply Chits.
        </Typography>
        <Accordion
          sx={{
            marginBottom: '24px',
            borderRadius: '12px',
            backgroundColor: '#3a87bb19',
            display: { xs: 'flex', md: 'none' },
            flexDirection: 'column',
          }}
        >
          <AccordionSummary sx={{ fontWeight: 600, fontSize: '16px' }}>
            More about Siply
          </AccordionSummary>
          <AccordionDetails sx={{ fontSize: '14px' }}>
            Siply is an Augmont & IBG eChits partnered, saving and borrowing
            app. In Siply Gold, you can buy 24K gold jewellery and coin starting
            at just ₹450/month. And can borrow upto ₹1,00,000 in Siply Chits.
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="Copy-Right">
        <span>
          © 2020-21{' '}
          <a target="_blank" href={Links.siplyWebsite} rel="noreferrer">
            Siply Services Private Limited
          </a>
          , All rights reserved. Built with <em>♥</em> in India
          <br />
          [CIN-U74999K2020PTC136118] [ARN-171848]
        </span>
      </div>
      <div className="Policy-Links">
        {policyLinks.map((item, index) => {
          return (
            <a href={item.link} target="_blank" rel="noreferrer" key={index}>
              {item.text}
            </a>
          )
        })}
      </div>
    </footer>
  )
}

export function SimpleFooter() {
  return (
    <footer className="Simple-Footer">
      <div className="divider">
        <img src={Divider} alt="" />
      </div>
      <span> With Siply&#39;s Govt. Licensed Chits Partner</span>
      <div className="Esign-Government-Images">
        <img className="Esign-Image" src={ESignImage} alt="e-sign image" />
        <img
          className="Government-Logo"
          src={GovenmentLicensedLogo}
          alt="government licensed logo"
        />
      </div>
    </footer>
  )
}

export default Footer

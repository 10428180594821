import './home-stats.css'

interface IStatSubtext {
  stat: string
  subtext: string
}

export function StatSubtext(data: IStatSubtext) {
  return (
    <div className="Stat-Subtext">
      <span className="stat">{data.stat}</span>
      <span className="subtext">{data.subtext}</span>
    </div>
  )
}

const statsMap: IStatSubtext[] = [
  {
    stat: '85K',
    subtext: 'Users Enrolled',
  },

  {
    stat: '10%',
    subtext: 'Avg. Interest Earned',
  },
  {
    stat: '278',
    subtext: 'Auctions Happened',
  },
  {
    stat: '45K+',
    subtext: 'Happy Customers',
  },
  {
    stat: '4Cr+',
    subtext: 'Amount Saved',
  },
]

export function HomeStats() {
  return (
    <div className="Home-Stats-Wrap">
      <div className="Home-Stats">
        {statsMap.map((stat, index) => {
          return (
            <div key={index} className="Stat-Box">
              <StatSubtext {...stat} />
              {index < statsMap.length - 1 ? (
                <div className="Vertical-Line"></div>
              ) : (
                <div></div>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export function HomeStatsMobile() {
  return (
    <div className="Home-Stats-Mobile-Wrap">
      <span className="Text">Achievements</span>
      <div className="Home-Stats-Mobile">
        {statsMap.map((stat, index) => {
          return (
            <div key={index} className="Stat-Box-Mobile">
              <StatSubtext {...stat} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

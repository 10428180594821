import { styled } from '@mui/material/styles'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary'
import { useCountdown } from '../helperFunctions'

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  backgroundColor: '#ffffff00',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}))

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(() => ({
  backgroundColor: '#ffffff00',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
}))

/**
 * can be custom styled with 'digits' class\
 * **NOTE:** Negative Countdowns i.e, CountUps ARE possible.\
 * Example:\
 * 60 seconds\
 * `<CoundownTimer time={60 * 1000 + Date.now()} />`
 *
 * use this **Date** format to avoid unnecessary errors\
 * `<CoundownTimer time={'2023-01-06 20:00:00'} />`
 */
export function CountdownTimer(props: {
  time: number | string
  addHours?: boolean
}) {
  const timer = useCountdown(props.time)
  return (
    <span className="digits">
      {(props.addHours === true
        ? String(timer.hours).padStart(2, '0') + ' : '
        : '') +
        String(timer.minutes).padStart(2, '0') +
        ' : ' +
        String(timer.seconds).padStart(2, '0')}
    </span>
  )
}

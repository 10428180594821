import { axiosClient } from '../../../core/api/apiSetup'
import { EndPoints } from '../../../core/api/endPoints'
import { getAccessToken } from '../../common/helperFunctions'
import {
  AddCurrentAddressRequest,
  ChitsKYCResponse,
  GenerateAadhaarOTPRequest,
  InitiateAadhaarResponse,
  KYCBasicDetailsRequest,
  NomineeRequest,
  PincodeResponse,
  VerifyAadhaarOtpResponse,
} from './kyc.models'

export async function getKYCDetails() {
  try {
    axiosClient.defaults.headers.common = {
      Authorization: getAccessToken(),
    }
    const res = await axiosClient.post(EndPoints.getKycDetails, {})
    console.log('getKYCDetails=>', res.data)
    return res.data as ChitsKYCResponse
  } catch (error) {
    console.error('getKYCDetails=>', error)
    return { status: false }
  }
}

export async function postKycBasicDetails(props: KYCBasicDetailsRequest) {
  try {
    axiosClient.defaults.headers.common = {
      Authorization: getAccessToken(),
    }
    const res = await axiosClient.post(EndPoints.postKycBasicDetails, props)
    console.log('postKycBasicDetails =>', res.data)
    return res.data as { status: boolean }
  } catch (error) {
    console.error('postKycBasicDetails =>', error)
    return { status: false }
  }
}

export async function initiateAadhaar() {
  try {
    axiosClient.defaults.headers.common = {
      Authorization: getAccessToken(),
    }
    const res = await axiosClient.post(EndPoints.initiateAadhaar, {
      consent: 'true',
    })
    console.log('initiateAadhaar =>', res.data)
    return res.data as InitiateAadhaarResponse
  } catch (error) {
    console.error('initiateAadhaar =>', error)
    return { status: 'Failure' }
  }
}

export async function resendAadhaarCaptcha() {
  try {
    axiosClient.defaults.headers.common = {
      Authorization: getAccessToken(),
    }
    const res = await axiosClient.post(EndPoints.initiateAadhaar, {
      consent: 'true',
    })
    console.log('resendAadhaarCaptcha =>', res.data)
    return res.data as InitiateAadhaarResponse
  } catch (error) {
    console.error('resendAadhaarCaptcha =>', error)
    return { status: 'Failure' }
  }
}

export async function generateAadhaarOTP(props: GenerateAadhaarOTPRequest) {
  try {
    axiosClient.defaults.headers.common = {
      Authorization: getAccessToken(),
    }
    const res = await axiosClient.post(EndPoints.generateAadhaarOTP, {
      consent: 'true',
      aadharNum: props.aadharNum,
      captcha: props.captcha,
    })
    console.log('generateAadhaarOTP =>', res.data)
    return res.data as { status: string; message: string }
  } catch (error) {
    console.error('generateAadhaarOTP =>', error)
    return { status: 'Failure', message: 'Something went wrong' }
  }
}

export async function verifyAadhaarOTP(otp: string) {
  try {
    axiosClient.defaults.headers.common = {
      Authorization: getAccessToken(),
    }
    const res = await axiosClient.post(EndPoints.validateAadhaarOTP, {
      consent: 'true',
      otp,
    })
    console.log('verifyAadhaarOTP =>', res.data)
    return res.data as VerifyAadhaarOtpResponse
  } catch (error) {
    console.error('verifyAadhaarOTP =>', error)
    return { status: 'Error' }
  }
}

export async function getPincode() {
  try {
    axiosClient.defaults.headers.common = {
      Authorization: getAccessToken(),
    }
    const res = await axiosClient.get(EndPoints.getPincode)
    console.log('getPincode =>', res.data)
    return res.data as PincodeResponse
  } catch (error) {
    console.error('getPincode =>', error)
    return { status: false }
  }
}

export async function saveCurrentAddress(props: AddCurrentAddressRequest) {
  try {
    axiosClient.defaults.headers.common = {
      Authorization: getAccessToken(),
    }
    const res = await axiosClient.post(EndPoints.saveCurrentAddress, props)
    console.log('saveCurrentAddress =>', res.data)
    return res.data as { status: boolean; message?: string }
  } catch (error) {
    console.error('saveCurrentAddress =>', error)
    return { status: false }
  }
}

export async function saveNomineeDetails(props: NomineeRequest) {
  try {
    axiosClient.defaults.headers.common = {
      Authorization: getAccessToken(),
    }
    const res = await axiosClient.post(EndPoints.saveNomineeDetails, props)
    console.log('saveNomineeDetails =>', res.data)
    return res.data as { status: boolean; message?: string }
  } catch (error) {
    console.error('saveNomineeDetails =>', error)
    return { status: false }
  }
}

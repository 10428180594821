declare const gtag: Gtag.Gtag

export const GA_TRACKING_ID: string = 'G-G66J0GMFHZ'
export const GTM_ID = 'GTM-T4HWBKN'

export const Events = {
  leadSubmit: 'LEAD_SUBMIT',
  signUp: 'SIGN_UP',
  signUpOtpSubmit: 'SIGN_UP_OTP_SUBMIT',
  checkEligibility: 'CHECK_ELIGIBILITY',
  pincodeNotAllowed: 'PINCODE_NOT_ALLOWED',
  chitsEligible: 'ELIGIBLE',
  kycBasicDetailsSubmit: 'KYC_BASIC_DETAILS_SUBMIT',
  kycAadhaarOtpVerify: 'KYC_AADHAAR_OTP_VERIFY',
  kycAadhaarOtpSubmit: 'KYC_AADHAAR_OTP_SUBMIT',
  kycAadhaarCurrentAddrsChange: 'KYC_AADHAAR_CURRENT_ADDRESS_CHANGE',
  kycAadhaarCurrentAddrsSubmit: 'KYC_AADHAAR_CURRENT_ADDRESS_SUBMIT',
  kycAadhaarSubmit: 'KYC_AADHAAR_SUBMIT',
  kycNomineeSubmit: 'KYC_NOMINEE_SUBMIT',
  paymentSuccess: 'PAYMENT_SUCCESS',
  paymentFailure: 'PAYMENT_FAILURE',
  esignAvailable: 'ESIGN_AVAILABLE',
  esignClick: 'ESIGN_CLICK',
}

export const useEventTrigger = (event: string, params?: any) => {
  const eventString =
    process.env.REACT_APP_ENV === 'development' ? 'STAGING_' + event : event
  gtag('event', eventString, params)
  console.log('Event Triggered => ', eventString, params)
}

import AppScreenshot from '../../../assets/images/app_screenshot.webp'
import { StatSubtext } from '../home/HomeStats'
import AppStoreIcon from '../../../assets/images/appstore.webp'
import PlayStoreIcon from '../../../assets/images/playstore.webp'
import './download-app.css'
import { Links } from '../../../core/api/endPoints'

function DownloadButton(props: {
  imgPath: string
  store: string
  link: string
  alt: string
}) {
  return (
    <a
      className="Download-Button"
      href={props.link}
      target="_blank"
      rel="noreferrer"
    >
      <img src={props.imgPath} alt={props.alt} />
      <span>
        Available on the
        <br />
        <strong>{props.store}</strong>
      </span>
    </a>
  )
}

function DownloadApp(props: { hasBackground: boolean; addMargin: boolean }) {
  return (
    <>
      <div className="anchor" id="download-app" />
      <div
        style={{
          backgroundColor: props.hasBackground ? '#3a87bb19' : '#fff',
          margin: props.addMargin ? '24px' : 0,
          textAlign: 'center',
          borderRadius: '10px',
        }}
      >
        <div className="Download-Title">
          Manage Your Chit Plans with{' '}
          <strong>
            <em>Siply App</em>
          </strong>
        </div>
        <div className="Download-Img-Contents">
          <img src={AppScreenshot} alt="screenshot of Siply app homepage" />
          <div className="Download-Contents">
            <div className="Download-Text">
              Get onboarded to India&#39;s 100% digital Chits. Pay your
              subscription, check your savings and earnings, participate in
              auctions and win auction prize and much more.
            </div>
            <div className="Download-Stats">
              <StatSubtext {...{ stat: '1M+', subtext: 'Downloads' }} />
              <StatSubtext {...{ stat: '4.2', subtext: 'Avg. Rating' }} />
              <StatSubtext {...{ stat: '10K+', subtext: 'Reviews' }} />
            </div>
            <div className="Download-Buttons">
              <DownloadButton
                link={Links.siplyAppStore}
                store="App Store"
                imgPath={AppStoreIcon}
                alt="app store icon"
              />
              <DownloadButton
                link={Links.siplyPlayStore}
                store="Play Store"
                alt="play store icon"
                imgPath={PlayStoreIcon}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DownloadApp

import jwtDecode, { JwtPayload } from 'jwt-decode'
import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

export function currencyFormatter(
  amount: string | number | undefined,
  addCommas: boolean = true
) {
  if (amount === undefined) return ''
  let formattedNumber: number
  if (typeof amount === 'string') {
    formattedNumber = parseInt(amount)
  } else {
    formattedNumber = amount
  }
  return new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    useGrouping: addCommas,
  }).format(formattedNumber)
}

/**
 * Returns comma spaced string
 */
export function addCommas(amount: string) {
  let formattedNumber: number
  if (typeof amount === 'string') {
    formattedNumber = parseInt(amount)
  } else {
    formattedNumber = amount
  }
  return new Intl.NumberFormat('en-IN').format(formattedNumber)
}

export function dateHandler(
  date: string,
  display: string = 'DD MMM YYYY',
  dateFormat: string = 'DD-MM-YYYY HH:mm:ss'
) {
  dayjs.extend(customParseFormat)
  const final = dayjs(date, dateFormat, 'en-IN').format(display)
  return final === 'Invalid Date' ? '--' : final
}

export function isValidName(s: string) {
  return s.match(/^[a-zA-Z ]+$/) !== null
}

export function isValidMobile(s: string) {
  return s.length === 10 && s.match(/[56789][0-9]{9}/) !== null
}

export function isValidOtp(s: string) {
  return s.match(/[0-9]{6}/) !== null
}

export function isValidEmail(s: string) {
  return (
    s.toLowerCase().match(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/) !== null
  )
}

interface jwt {
  mobileNumber: BigInt
}

export const setToken = (jwt: string) => {
  // Expires in 24 hours from login
  const expireIn = new Date(Date.now() + 60 * 60 * 1000 * 24)
  Cookies.set('access_token', jwt, {
    expires: expireIn,
    sameSite: 'Strict',
  })
}

export const getAccessToken = () => Cookies.get('access_token')
export const isAuthenticated = () => getAccessToken() !== undefined

export function getUserMobile() {
  const token = getAccessToken()
  if (token !== undefined && token.length > 0) {
    const decoded = jwtDecode<JwtPayload & jwt>(token)
    return decoded.mobileNumber
  }
}

export function isValidPAN(pan: string): boolean | undefined {
  return /[A-Z]{5}[0-9]{4}[A-Z]{1}/.test(pan.toUpperCase())
}

export function useCountdown(targetDate: string | number) {
  const countDownDate = new Date(targetDate).getTime()
  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  )
  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime())
    }, 1000)

    return () => clearInterval(interval)
  }, [countDownDate])

  const hours = Math.floor(countDown / (1000 * 60 * 60))
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000)

  return countDown > 0
    ? { hours, minutes, seconds }
    : { hours: 0, minutes: 0, seconds: 0 }
}
